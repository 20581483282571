<template>
  <div>
    <!-- <Modal
      v-model='detailModal'
      width="800"
    >
      <p
        slot="header"
        class="text-center"
      >查看版本配置详情</p> -->
      <Table
        v-if="titleDesc!==''"
        stripe
        size="small"
        :data="tableList"
        :columns="tableColumns"
      ></Table>
      <p
        class="text-center p-t-10 text-16 text-gray"
        v-else
      >暂无数据</p>

    <!-- </Modal> -->

  </div>
</template>

<script>
export default {
  props: {
    titleDesc: {
      type: String
    },
    list: [Array, String, Object]
  },
  data () {
    return {
      detailModal: false,
      tableList: [],
      tableColumns: []
    }
  },
  methods: {
    initData () {
      this.tableColumns = []
      // this.detailModal = true

      if (this.titleDesc && this.titleDesc.length) {
        const title = JSON.parse(this.titleDesc)
        for (const key in title) {
          const column = {
            title: title[key],
            align: 'center',
            key: key
          }
          this.tableColumns.push(column)
        }
      }
    }

  },
  watch: {
    list: {
      deep: true,
      immediate: true,
      handler (val) {
        if (val) {
          this.tableList = val
        }
      }
    }
  }
}
</script>
